.ant-menu-item svg, .ant-menu-submenu-title svg {
  margin-right: 7px
}

.ant-page-header {
  padding: 0 0 25px 0;
}

.custom-descriptions-title {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
}

.row-clickable {
  cursor: pointer;
}

.ant-result-icon {
  font-size: 104px;
  color: #52c41a;
}

.ant-input-prefix svg {
  color: rgba(0,0,0,.25);
}