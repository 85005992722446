.print-zone {
    margin: 40px;
}

.back-button {
    text-align: center;
    width: 100%;
    margin-top: 30px;
}

.back-button > button {
    margin-left: 20px;
}

@media print {
    .print-zone {
        margin: 0px;
    }
    body {
        margin: 0px;
    }
    .back-button {
        display: none;
    }
}

@page {
    margin: 20mm 15mm;
}