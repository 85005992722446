.icon-wrapper {
    position: relative;
    padding: 0px 50px;
    min-width: 300px;
}

.icon-wrapper svg {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    line-height: 1;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.25);
}

.icon-wrapper svg:first-child {
    left: 20px;
}

.icon-wrapper svg:last-child {
    right: 20px;
}

.ant-slider-mark {
    font-size: 12px !important;
    top: 20px !important;
}

.ant-slider-mark-text {
    width: 150px;
}