.login-form {
  max-width: 300px;
  margin: auto;
  padding-top: 3%;
}

.login-form-button {
  width: 100%;
}

.login-form-header {
  text-align: center;
}

h1 {
  color: rgba(0, 0, 0, 0.65);
  font-size: 30px;
  font-weight: 400;
  margin: 10px 0 30px 0;
}