.logo, li.logo {
  font-size: 20px;
  cursor: unset !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.logo svg {
  margin-right: 7px;
  font-size: 28px;
}
.logo small {
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  margin-left: 10px;
}