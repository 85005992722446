.note-editor {
    min-height: 250px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    cursor: text;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
    min-width: 35px !important;
    min-height: 35px !important;
}

.rdw-option-wrapper:hover,
.rdw-option-active,
.rdw-dropdown-wrapper:hover {
    box-shadow: unset !important;
    background-color: #F1F1F1 !important;
}

.rdw-dropdown-wrapper a span {
    color: rgba(0, 0, 0, 0.65);
}

.rdw-fontsize-dropdown {
    min-width: 50px !important;
}

.rdw-dropdown-optionwrapper:hover {
    box-shadow: unset !important;
}

.rdw-dropdown-optionwrapper {
    width: 100% !important;
}